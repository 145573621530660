<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
  >
    <path
      d="M2.73334 0V9.06667M2.73334 9.06667C2.16754 9.06667 1.62492 9.29143 1.22484 9.69151C0.824767 10.0916 0.600006 10.6342 0.600006 11.2C0.600006 11.7658 0.824767 12.3084 1.22484 12.7085C1.62492 13.1086 2.16754 13.3333 2.73334 13.3333M2.73334 9.06667C3.29913 9.06667 3.84176 9.29143 4.24183 9.69151C4.64191 10.0916 4.86667 10.6342 4.86667 11.2C4.86667 11.7658 4.64191 12.3084 4.24183 12.7085C3.84176 13.1086 3.29913 13.3333 2.73334 13.3333M2.73334 13.3333V16M11.2667 0V2.66667M11.2667 2.66667C10.7009 2.66667 10.1583 2.89143 9.75818 3.29151C9.3581 3.69158 9.13334 4.23421 9.13334 4.8C9.13334 5.3658 9.3581 5.90842 9.75818 6.30849C10.1583 6.70857 10.7009 6.93333 11.2667 6.93333M11.2667 2.66667C11.8325 2.66667 12.3751 2.89143 12.7752 3.29151C13.1752 3.69158 13.4 4.23421 13.4 4.8C13.4 5.3658 13.1752 5.90842 12.7752 6.30849C12.3751 6.70857 11.8325 6.93333 11.2667 6.93333M11.2667 6.93333V16"
      stroke="black"
    />
  </svg>
</template>
